import "flatpickr/dist/themes/light.css";
import "flatpickr";

import { German } from "flatpickr/dist/l10n/de.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { English } from "flatpickr/dist/l10n/default.js";

export function initDatePicker() {
    let locale = English;
    if (LANGUAGE === "de") {
        locale = German;
    } else if (LANGUAGE === "fr") {
        locale = French;
    }
    flatpickr.localize(locale);

    document
        .querySelectorAll("input.dateinput:not(.flatpickr-input)")
        .flatpickr({
            allowInput: true,
            time_24hr: true,
            weekNumbers: true,
            enableTime: false,
            dateFormat: DATE_FORMAT,
        });
    document
        .querySelectorAll("input.datetimeinput:not(.flatpickr-input)")
        .flatpickr({
            allowInput: true,
            time_24hr: true,
            weekNumbers: true,
            enableTime: true,
            dateFormat: DATETIME_FORMAT,
        });
}
