const state = {
    missedCalls: [],
    incomingCalls: [],
    outgoingCalls: [],
    hotdesks: {},
    hotdeskQueues: {},
    phonebookentries: [],
    isHotdeskQueuesInitialized: false,
    is_hotdesk_active: true,
    is_hotdesk_loading: false,
    hotdeskNumber: undefined,
};

export default state;
