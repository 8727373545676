export const SET_HOTDESK_QUEUES = "SET_HOTDESK_QUEUES";
export const SET_HOTDESK_QUEUE = "SET_HOTDESK_QUEUE";

export const SET_QUEUE_STATUS = "SET_QUEUE_STATUS";
export const SET_QUEUE_TYPE = "SET_QUEUE_TYPE";

export const SET_MISSED_CALLS = "SET_MISSED_CALLS";
export const SET_INCOMING_CALLS = "SET_INCOMING_CALLS";
export const SET_OUTGOING_CALLS = "SET_OUTGOING_CALLS";

export const SET_HOTDESKS = "SET_HOTDESKS";
export const SET_RECENT_SIP_ACCOUNTS = "SET_RECENT_SIP_ACCOUNTS";
export const SET_HOTDESK_NUMBER = "SET_HOTDESK_NUMBER";
export const SET_HOTDESK = "SET_HOTDESK";
export const SET_HOTDESK_LOADING = "SET_HOTDESK_LOADING";

export const SET_PHONEBOOK_ENTRIES = "SET_PHONEBOOK_ENTRIES";
