import Vue from "vue";

import {
    SET_HOTDESK,
    SET_HOTDESK_LOADING,
    SET_HOTDESK_QUEUES,
    SET_HOTDESK_QUEUE,
    SET_HOTDESKS,
    SET_RECENT_SIP_ACCOUNTS,
    SET_INCOMING_CALLS,
    SET_MISSED_CALLS,
    SET_OUTGOING_CALLS,
    SET_QUEUE_STATUS,
    SET_HOTDESK_NUMBER,
    SET_QUEUE_TYPE,
    SET_PHONEBOOK_ENTRIES,
} from "./mutation-types";

const mutations = {
    [SET_HOTDESK_QUEUES](state, { queues }) {
        for (const queue of queues) {
            Vue.set(state.hotdeskQueues, queue.name, queue);
        }
        state.isHotdeskQueuesInitialized = true;
    },

    [SET_HOTDESK_QUEUE](state, { queue }) {
        Vue.set(state.hotdeskQueues, queue.name, queue);
    },

    [SET_QUEUE_STATUS](state, { queue, status }) {
        if (state.hotdeskQueues[queue] === undefined) {
            return;
        }
        for (let member of state.hotdeskQueues[queue].members) {
            if (member.hotdesk_extension === state.hotdeskNumber) {
                member.status = status;
            }
        }
    },

    [SET_QUEUE_TYPE](state, { queue, type }) {
        if (state.hotdeskQueues[queue] === undefined) {
            return;
        }
        for (let member of state.hotdeskQueues[queue].members) {
            if (member.hotdesk_extension === state.hotdeskNumber) {
                member.type = type;
            }
        }
    },

    [SET_MISSED_CALLS](state, { calls }) {
        state.missedCalls = calls;
    },

    [SET_INCOMING_CALLS](state, { calls }) {
        state.incomingCalls = calls;
    },

    [SET_OUTGOING_CALLS](state, { calls }) {
        state.outgoingCalls = calls;
    },

    [SET_HOTDESKS](state, { hotdesks }) {
        for (const hotdesk of hotdesks) {
            Vue.set(state.hotdesks, hotdesk.number, hotdesk);
        }
    },

    [SET_HOTDESK](state, { hotdesk }) {
        Vue.set(state.hotdesks, hotdesk.number, hotdesk);
        state.is_hotdesk_loading = false;
    },

    [SET_HOTDESK_LOADING](state, { isLoading }) {
        state.is_hotdesk_loading = isLoading;
    },

    [SET_HOTDESK_NUMBER](state, { hotdeskNumber }) {
        state.hotdeskNumber = hotdeskNumber;
    },

    [SET_RECENT_SIP_ACCOUNTS](state, { recentSipAccounts }) {
        state.recentSipAccounts = recentSipAccounts;
    },

    [SET_PHONEBOOK_ENTRIES](state, { phonebookentries }) {
        state.phonebookentries = [];
        for (const entry of phonebookentries) {
            state.phonebookentries.push({
                phonebook_name: entry.name,
                phonebook_number: entry.number,
            });
        }
    },
};

export default mutations;
